import { QueryParamsEntities } from "../../../domain/entities/MainApp";

export class RequestListOnProcessStt {
  page = 1;
  limit = 20;
  search = "";
  originDistrictId = "";
  destinationDistrictId = "";
  productTypes = "";
  isCod = false;
  isDfod = false;
  insuranceTypes = "";
  statusType = "";
  version = 2;
  isTotalData = false;
  constructor(fields?: Partial<RequestListOnProcessStt>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
